<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <h2 class="text-primary mb-1">
        {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
      </h2>
      <div class="d-flex align-items-center">
        <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'cabang' })">
          <feather-icon icon="ListIcon" />
          <span class="d-none d-md-inline">Back To List</span>
        </b-button>
      </div>
    </div>

    <vue-form-json-schema v-model="modelData" :schema="schema" :ui-schema="uiSchema" />

    <b-row>
      <b-col md="12">
        <validation-provider #default="{ errors }" name="kapasitas seat" rules="required">
          <b-form-group label="Kapasitas Seat" label-for="kapasitas_seat">
            <cleave id="kapasitas_seat" v-model="modelData.kapasitas_seat" class="form-control" :raw="false" max="100"
              :options="options.number" placeholder="Masukkan kapasitas seat" />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    </b-row>

    <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleSubmit">
      <feather-icon class="mr-50" icon="SaveIcon" />
      Save
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import uiSchema from './schema/FormRequestUiSchema.json'
import schema from './schema/FormRequestSchema.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
      modelData: {
        namacabang: '',
        kapasitas_seat: '',
      },
      idCabang: 0,
      schema,
      uiSchema,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetailData()
    }
  },
  methods: {
    handleSubmit() {
      if (this.$route.params.id) {
        this.handleEdit()
      } else {
        this.handleCreate()
      }
    },

    handleCreate() {
      if (!this.validateForm()) {
        return
      }

      const data = {
        kodecabang: this.data.idCabang,
        namacabang: this.modelData.namacabang,
        kapasitas_seat: parseInt(this.modelData.kapasitas_seat.replace(/,/g, '')),
      }
      this.$http.post('/api/cabang/create', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/cabang')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    handleEdit() {
      if (!this.validateForm()) {
        return
      }
      const data = {
        kodecabang: this.data.idCabang,
        namacabang: this.modelData.namacabang,
        kapasitas_seat: parseInt(this.modelData.kapasitas_seat.replace(/,/g, '')),
      }
      this.$http.post('/api/cabang/edit', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/cabang')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    getDetailData() {
      this.$http.get(`/api/cabang/detail/${this.$route.params.id}`).then(res => {
        const response = res.data.data
        this.data = { idCabang: response.kodecabang }
        this.modelData = {
          namacabang: response.namacabang,
          kapasitas_seat: response.kapasitas_seat,
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
      })
    },

    validateForm() {
      if (this.modelData.namacabang === '' || this.modelData.namacabang === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi nama cabang',
          },
        });
        return false;
      }

      if (this.modelData.kapasitas_seat === '' || this.modelData.kapasitas_seat === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi kapasitas seat',
          },
        });
        return false;
      }
      if (!this.modelData.kapasitas_seat || !/^[0-9]/.test(this.modelData.kapasitas_seat)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Kapasitas seat hanya mengandung angka',
          },
        });
        return false
      }

      return true
    }
  }
}
</script>
